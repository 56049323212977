import * as Sentry from "@sentry/browser"
import { Replay } from "@sentry/replay"
import { BrowserTracing } from "@sentry/tracing"

const {
  sentryReplaysSampleRate,
  sentryReplaysErrorSampleRate,
  sentryTracesSampleRate,
  user,
  company,
  person,
} = gon.sentry_config

Sentry.init({
  dsn: window.sentry_dsn,
  ignoreErrors: [/^ResizeObserver loop limit exceeded$/],
  integrations: [
    new BrowserTracing(),
    new Replay({
      maskAllText: true,
      blockAllMedia: true,
      inlineStylesheet: false,
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: sentryTracesSampleRate,

  // You may want this to be 100% while in development and sample at a lower
  // rate in production
  replaysSessionSampleRate: sentryReplaysSampleRate,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: sentryReplaysErrorSampleRate,
})

if (user) {
  Sentry.setUser(gon.sentry_config.user)
}
if (company) {
  Sentry.setContext("company", gon.sentry_config.company)
}
if (person) {
  Sentry.setContext("person", gon.sentry_config.person)
}

window.Sentry = Sentry
